import React from "react";
import { graphql } from "gatsby"


import Header from "../components/header";
import Main from "../components/main";
import Numbers from "../components/numbers";
import Footer from "../components/footer";
import SEO from "../components/seo";

import "../components/global.css";

const IndexPaginated = ({data, pageContext}) => {
  const blogPosts = data.allContentfulBlogPost.edges;
  console.log(data);
  return (
    <div>
      <SEO title="Home" keywords={[`rjk`, `code`, `blog`]} />
      <Header/>
      <Main posts={blogPosts} />
      <Numbers total={pageContext.numPages} selected={pageContext.currentPage}/>
      <Footer/>
    </div>
  )
};

export const query = graphql`
  query IndexPageQueryPaginated($skip: Int!, $limit: Int!) {
    allContentfulBlogPost(
      sort: { fields: [createdAt], order: DESC }, 
      limit: $limit,
      skip: $skip
    ) {
      edges {
        node {
          id
          title
          createdAt
          slug
          synopsis {
            synopsis
          }
          body {
            body
          }
          image {
            fluid(maxWidth: 400){
              ...GatsbyContentfulFluid_withWebp
            }
          }
          tags
        }
      }
      totalCount
    }
  }
`;

export default IndexPaginated;